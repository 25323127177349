.sidenav {
  color: rgb(65, 61, 61);
  width: 150;
}
.App {
  font-family: sans-serif;
  text-align: center;
}

.ibutton{
background-color:rgba(0, 0, 0, 0.7) !important;
/* background-color: black !important; */
}

@media (max-width: 767px) {
  .card {
    display: none;
  }
}
.thead-dark {
  background-color: #343a40;
  color: #fff;
}

.formLebal {
  margin-top: 4%;
  font-size: 18px;
}

.search-container {
  position: relative;
}

.search-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #999;
}

a.active {
  background-color: #e7f7ff;
  text-decoration: none;
}

.sidebar .longbar {
  display: none;
}

.sidebar a.active .longbar {
  display: block;
}

.sidebar .slide-right {
  animation: slideRight 0.1s;
  -webkit-animation: slideRight 0.1s;
}

@keyframes slideRight {
  from {
    left: -20px;
    opacity: 0.5;
  }
  to {
    left: 0px;
    opacity: 1;
  }
}

.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}
